var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('step-progress',{staticClass:"mb-6",attrs:{"current":_vm.window,"total":_vm.questionCount,"hideTotal":_vm.window === 1}}),_c('div',{staticClass:"divider mb-6 opacity-80"}),_c('v-window',{attrs:{"touchless":""},model:{value:(_vm.window),callback:function ($$v) {_vm.window=$$v},expression:"window"}},[_c('v-window-item',{attrs:{"value":1}},[_c('memorial-remains-form',{attrs:{"submitLabel":"Next","onSaveBehaviour":"next"},on:{"close":_vm.close,"next":() => {
            _vm.remains === 'family' ? _vm.close() : _vm.nextStep()
          }}})],1),_vm._l((_vm.questions),function(question,index){return _c('v-window-item',{key:question.key,attrs:{"value":index + 2}},[_c('memorial-wish-form',{attrs:{"submitLabel":_vm.questionCount === _vm.window ? 'Save' : 'Next',"closeLabel":"Back","onSaveBehaviour":"next","qkey":question.key,"qgroup":_vm.qgroup},on:{"close":() => {
            _vm.window === 1 ? _vm.close() : _vm.prevStep()
          },"next":() => {
            _vm.questionCount === _vm.window ? _vm.close() : _vm.nextStep()
          }}})],1)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }